* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html {
  height: 100%;
  width: 100%;
}

body {
  background-color: white;
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none
}

#root {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: 'TSFont-Regular';
  src: url('./fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TSFont-ExtraBold';
  src: url('./fonts/Lato-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'TSFont-Light';
  src: url('./fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'TSFont-Bold';
  src: url('./fonts/Lato-Bold.ttf') format('truetype');
}